import { useState, memo } from "react"
import { useHistory } from "react-router-dom"
import styled from "@emotion/styled"

import { Card, Table } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { date } from "js/includes/common/utils"
import TicketStatusIcon from "js/includes/ticketing/common/TicketStatusIcon"
import { Flex } from "js/includes/components/Styled"
import { localizationKey } from "js/includes/common/utils/ssrAndWebUtils"

const StyledFlex = styled(Flex)`
  .virtualized-table .ReactVirtualized__Table__headerRow {
    display: flex;
  }
  .table-row {
    padding-left: ${sizer(3)};
  }
`

export const LinkedTickets = memo(({ data, titleGroup }) => {
  const history = useHistory()

  const [{ sortBy, sortDirection }, sort] = useState({
    sortBy: "summary",
    sortDirection: "DESC",
  })

  return (
    <Card>
      <StyledFlex flexGrow={1} height="100%" width="100%" flexDirection="column">
        <Table
          dataKey="id"
          titleGroup={titleGroup}
          data={data}
          sort={sort}
          sortBy={sortBy}
          sortDirection={sortDirection}
          singleRowCheck
          onRowClick={({ rowData }) => history.push(`/ticketing/ticket/${rowData.id}`)}
          searchable={["id", "summary"]}
          noRowsToken={localizationKey("There are no tickets")}
          columns={() => [
            {
              dataKey: "status",
              label: localizationKey("Status"),
              width: 1,
              flexGrow: 1,
              cellRenderer: ({ rowData: { status } }) => <TicketStatusIcon status={status} />,
            },
            {
              dataKey: "id",
              label: "ID", // localization removed
              width: 1,
              flexGrow: 1,
            },
            {
              dataKey: "summary",
              label: localizationKey("Name"),
              width: 1,
              flexGrow: 1,
            },
            {
              dataKey: "createTime",
              label: localizationKey("Time"),
              width: 1,
              flexGrow: 1,
              cellDataGetter: ({ rowData: { createTime } }) => date(createTime),
            },
          ]}
        />
      </StyledFlex>
    </Card>
  )
})
