import React, { PureComponent } from "react"
import styled from "@emotion/styled"
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { colors } from "js/includes/common/theme"
import { StepperButton } from "js/includes/editors/Policy/PolicyEditor/formComponents/StepperButton"
import { Box } from "js/includes/components/Styled"

const StyledStepperContainer = styled.div`
  display: flex;
`

const StyledInput = styled.input`
  border: ${({ withBorders, theme }) => (withBorders ? `1px solid ${theme.color.border}` : "none")};
  text-align: ${({ textAlign }) => textAlign || "center"};
  height: 30px;
  width: ${({ width }) => width || "100%"};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${colors.ninjaDark};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: 0;
  }
`

export default class NumberStepper extends PureComponent {
  constructor(props) {
    super(props)
    const { initialValue } = props
    this.state = {
      value: initialValue || 0,
      interval: null,
    }
    this.increment = this.increment.bind(this)
    this.decrement = this.decrement.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onLongPressIncrement = this.onLongPressIncrement.bind(this)
    this.onLongPressDecrement = this.onLongPressDecrement.bind(this)
    this.onLongPressEnd = this.onLongPressEnd.bind(this)
    this.resetValue = this.resetValue.bind(this)
  }

  componentWillUnmount() {
    clearInterval(this.state.interval)
  }

  handleChange(e) {
    const { maxValue, minValue, onChange, isFloat } = this.props
    const numericValue = Number(e.target.value)
    let val = isNaN(numericValue) ? 0 : numericValue
    if (val < minValue) val = minValue
    if (val > maxValue) val = maxValue
    this.setState(
      {
        value: isFloat ? val : parseInt(val),
      },
      () => {
        onChange && onChange(this.state.value)
      },
    )
  }

  increment() {
    const { maxValue, stepper, onChange, isFloat, digits, disabled } = this.props
    if (disabled) return
    const { value } = this.state

    const nextValue = isFloat ? (parseFloat(value) + parseFloat(stepper)).toFixed(digits) : value + stepper

    this.setState(
      {
        value: nextValue > maxValue ? maxValue : nextValue,
      },
      () => {
        onChange && onChange(this.state.value)
      },
    )
  }

  decrement() {
    const { minValue, stepper, onChange, isFloat, digits, disabled } = this.props
    if (disabled) return
    const { value } = this.state

    const nextValue = isFloat ? (parseFloat(value) - parseFloat(stepper)).toFixed(digits) : value - stepper

    this.setState(
      {
        value: nextValue < minValue ? minValue : nextValue,
      },
      () => {
        onChange && onChange(this.state.value)
      },
    )
  }

  onLongPressIncrement() {
    if (this.state.value === this.props.maxValue) return
    this.setState({
      interval: setInterval(() => {
        this.increment()
      }, 50),
    })
  }

  onLongPressDecrement() {
    if (this.state.value === this.props.minValue) return
    this.setState({
      interval: setInterval(() => {
        this.decrement()
      }, 50),
    })
  }

  onLongPressEnd() {
    clearInterval(this.state.interval)
  }

  resetValue() {
    this.setState({ value: this.props.minValue })
  }

  render() {
    const { value } = this.state
    const { suffix, disabled, withButtons = true, textAlign } = this.props
    return (
      <StyledStepperContainer>
        {withButtons && (
          <StepperButton
            onClick={this.decrement}
            onLongPress={this.onLongPressDecrement}
            onLongPressEnd={this.onLongPressEnd}
          >
            <FontAwesomeIcon icon={faMinus} />
          </StepperButton>
        )}
        <Box display="flex" width={withButtons ? "60px" : "120px"}>
          <StyledInput
            {...{
              type: "number",
              value,
              onChange: this.handleChange,
              disabled,
              textAlign,
              withBorders: !withButtons,
              ...(suffix && { width: "38px", textAlign: "end" }),
            }}
          />
          {suffix}
        </Box>
        {withButtons && (
          <StepperButton
            onClick={this.increment}
            onLongPress={this.onLongPressIncrement}
            onLongPressEnd={this.onLongPressEnd}
          >
            <FontAwesomeIcon icon={faPlus} color={colors.white} />
          </StepperButton>
        )}
      </StyledStepperContainer>
    )
  }
}
