import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import Heading from "./Typography/Heading"
import Body from "./Typography/Body"

function PageHeader({
  id,
  actionButtonsRenderer,
  breadcrumbsRenderer,
  descriptionText,
  helpTooltipRenderer,
  Icon,
  iconButtonsRenderer,
  noPaddingHorizontal,
  noPaddingBottom,
  titleText,
  topMetadataText,
}) {
  const breadcrumbs = breadcrumbsRenderer?.()
  return (
    <Flex
      id={id}
      flexDirection="column"
      padding={[
        breadcrumbs ? 0 : tokens.spacing[2],
        noPaddingHorizontal ? 0 : tokens.spacing[6],
        noPaddingBottom ? 0 : tokens.spacing[2],
      ]}
    >
      {breadcrumbs}
      {topMetadataText && <Body color="colorTextWeak">{topMetadataText}</Body>}
      <Flex justifyContent="space-between" alignItems="center" gap={tokens.spacing[6]}>
        <Flex flex={1} minWidth="1px" alignItems="center" gap={tokens.spacing[2]}>
          <Flex flexDirection="column" minWidth="1px" flex={1}>
            <Flex flex={1} minWidth="1px" alignItems="center" gap={tokens.spacing[2]}>
              {Icon && <Icon size="lg" />}
              <Heading color="colorTextStrong" type="headingL" level={1}>
                {titleText}
              </Heading>
              {helpTooltipRenderer?.()}
              {iconButtonsRenderer && <Flex>{iconButtonsRenderer()}</Flex>}
            </Flex>
            {descriptionText && <Body color="colorTextWeak">{descriptionText}</Body>}
          </Flex>
        </Flex>
        {actionButtonsRenderer && (
          <Flex gap={tokens.spacing[2]} alignSelf="flex-start">
            {actionButtonsRenderer()}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default PageHeader

PageHeader.propTypes = {
  actionButtonsRenderer: PropTypes.func,
  breadcrumbsRenderer: PropTypes.func,
  descriptionText: PropTypes.string,
  helpTooltipRenderer: PropTypes.func,
  Icon: PropTypes.elementType,
  id: PropTypes.string,
  iconButtonsRenderer: PropTypes.func,
  noPaddingHorizontal: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  titleText: PropTypes.string,
  topMetadataText: PropTypes.string,
}
