import { nanoid } from "nanoid"
import pathParse from "path-parse"
import { compose, map, reject } from "ramda"

import { bytes, isNumber } from "js/includes/common/utils"

const multipliers = {
  KB: 1024,
  MB: 1048576,
  GB: 1073741824,
}

export const reverseBytes = formattedBytes => {
  const [value, unit] = formattedBytes.split(" ")

  const numericValue = +value
  if (!isNumber(numericValue)) {
    return formattedBytes
  }

  if (!unit) {
    return numericValue
  }

  if (!(unit in multipliers)) {
    return formattedBytes
  }

  return numericValue * multipliers[unit]
}

export const mapAttachmentForAttachmentPlugin = attachment => ({
  file: {
    name: attachment.metadata.name,
    size: reverseBytes(attachment.metadata.size), // TODO change this when size is set differently in buildTempAttachment
    type: attachment.metadata.mimeType,
  },
  resourceId: attachment.resourceId,
})

export const parseAttachmentsForAttachmentPlugin = attachments => {
  return compose(
    map(mapAttachmentForAttachmentPlugin),
    reject(attachment => attachment.metadata.inline),
  )(attachments ?? [])
}

export const buildTempAttachment = ({ file, inline = false }) => {
  const { name, ext } = pathParse(file.name)

  return {
    resourceId: null,
    tempId: nanoid(),
    metadata: {
      name,
      mimeType: file.type,
      size: bytes(file.size), // TODO use another function since bytes unit is wrong (should be KiB instead of KB)
      extension: ext.replace(".", "").toLowerCase(),
      inline,
    },
  }
}
