import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized } from "js/includes/common/utils"
import NumberStepper from "js/includes/editors/Policy/PolicyEditor/formComponents/NumberStepper"

const ThresholdStepper = memo(({ label, values, onChange, thresholdType, minValue = 1, maxValue = 100 }) => (
  <FormGroup controlId="threshold">
    <Col componentClass={ControlLabel} sm={4}>
      {label || localized(thresholdType === "number" ? "Threshold" : "Threshold Percent")}
    </Col>
    <Col sm={8}>
      <NumberStepper
        minValue={minValue}
        maxValue={maxValue}
        initialValue={values.threshold}
        stepper={1}
        onChange={onChange}
      />
    </Col>
  </FormGroup>
))

export default ThresholdStepper
