import { createContext, lazy, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { connect } from "react-redux"
import { find, includes } from "ramda"
import { useTheme } from "@emotion/react"
import { Breadcrumb, Text } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import { color } from "@ninjaone/themes/base"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import { Box, Flex } from "js/includes/components/Styled"
import AdministrationTabs from "js/includes/application/SideNavBar/AdministrationTabs"
import { administrationMaxWidth, getAdministrationNavItems, isGrayBackgroundPadding } from "./utils"

const AdminRouter = lazy(() =>
  import(/* webpackChunkName: "AdminRouter" */ "js/includes/configuration/ConfigurationsRouter"),
)

const getTabBreadcrumbs = (tabs, pathname, breadcrumbs = []) => {
  for (let i = 0; i < tabs.length; i++) {
    const tab = tabs[i]
    const { route, baseRoute } = tab

    if (!!baseRoute ? pathname.startsWith(baseRoute) : pathname === route) {
      breadcrumbs.push({ ...tab, path: tab.path ?? route })

      if (tab.subtabs) {
        return getTabBreadcrumbs(tab.subtabs, pathname, breadcrumbs)
      }

      break
    }
  }

  return breadcrumbs
}

export const getAdminBreadcrumbs = ({ pathname, divisionConfig, initialAppsBreadcrumbs }) => {
  const { name = "", to: path = "", subtabs = null } =
    find(({ to, baseUrl }) => includes(baseUrl ?? to)(pathname))(getAdministrationNavItems({ divisionConfig })) || {}

  const tabBreadcrumbs = !!subtabs ? getTabBreadcrumbs(subtabs, pathname) : []

  return [
    ...initialAppsBreadcrumbs,
    {
      name,
      path,
    },
    ...tabBreadcrumbs,
  ]
}

export const BreadcrumbContext = createContext()

const AdministrationView = ({ divisionConfig }) => {
  const [noPadding, setNoPadding] = useState()
  const location = useLocation()
  const theme = useTheme()
  const [breadcrumbs, setBreadcrumbs] = useState([])

  useEffect(() => {
    setNoPadding(isGrayBackgroundPadding())
  }, [location])

  const initialAppsBreadcrumbs = useMemo(
    () => [
      {
        name: localized("Home"),
        path: "#/systemDashboard/overview",
      },
      {
        name: localized("Administration"),
        path: "#/administration/general/settings",
      },
    ],
    [],
  )

  useEffect(() => {
    const updatedBreadcrumbs = getAdminBreadcrumbs({
      divisionConfig,
      pathname: location.pathname,
      initialAppsBreadcrumbs,
    })
    setBreadcrumbs(updatedBreadcrumbs)
  }, [initialAppsBreadcrumbs, location.pathname, divisionConfig])

  return (
    <Flex width="100%" height="100%" flexDirection="column">
      <Box padding={sizer(0, 6)}>
        <Breadcrumb {...{ history: breadcrumbs, disableLastLink: true }} />
      </Box>
      <Box padding={sizer(0, 6, 4)} borderBottom={`1px solid ${color.divider}`} data-test-page="page-title">
        <Text size="lg" token={localizationKey("Administration")} color={theme.color.inputText} bold />
      </Box>
      <Flex width="100%" height="100%" overflow="hidden">
        <AdministrationTabs />
        <Flex
          data-test-wrapper="main-content-wrapper"
          padding={noPadding ? "" : sizer(0, 6)}
          width="100%"
          flexDirection="column"
          maxWidth={noPadding ? "" : administrationMaxWidth}
        >
          <BreadcrumbContext.Provider
            value={{
              setBreadcrumbs,
            }}
          >
            <AdminRouter />
          </BreadcrumbContext.Provider>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default connect(({ session }) => ({
  divisionConfig: session.divisionConfig,
}))(AdministrationView)
