import React, { PureComponent } from "react"
import { assocPath } from "ramda"
import { v4 as uuidv4 } from "uuid"
import EditorPortalModal from "js/includes/components/EditorPortalModal"
import { ConditionsModal } from "./modals"
import { connect } from "react-redux"
import { updatePolicyItem, deletePolicyItem } from "js/state/actions/policyEditor/editor"
import {
  generateConditionForServer,
  getPathToCondition,
} from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import ConditionsList from "./ConditionsList"
import { localized, localizationKey, getPsaTypeLabel, isTicketingEnabledFromSettings } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import { sizer } from "@ninjaone/utils"
import { colors } from "js/includes/common/theme"

class Conditions extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      showConditionModal: false,
      condition: {},
    }

    this.handleAddCondition = this.handleAddCondition.bind(this)
    this.onCloseConditions = this.onCloseConditions.bind(this)
    this.onSaveCondition = this.onSaveCondition.bind(this)
    this.handleSelectedCondition = this.handleSelectedCondition.bind(this)
    this.handleOnToggleEnabled = this.handleOnToggleEnabled.bind(this)
  }

  handleSelectedCondition(conditionUid) {
    const {
      content: { conditions },
    } = this.props.policy

    this.setState({
      showConditionModal: true,
      condition: conditions[conditionUid],
      pathToItem: getPathToCondition(conditionUid),
    })
  }

  handleOnToggleEnabled(conditionUid, condition) {
    const { updatePolicyItem, parentPolicy } = this.props
    const pathToItem = getPathToCondition(conditionUid)

    const toggledEnabledCondition = assocPath(["enabled"], !condition.enabled, condition)

    updatePolicyItem(pathToItem, parentPolicy, toggledEnabledCondition)
  }

  handleAddCondition() {
    const conditionId = uuidv4()

    this.setState({
      showConditionModal: true,
      pathToItem: getPathToCondition(conditionId),
    })
  }

  onCloseConditions() {
    this.setState({
      showConditionModal: false,
      pathToItem: null,
      condition: {},
    })
  }

  onSaveCondition(conditionData) {
    const { pathToItem } = this.state
    const { updatePolicyItem, parentPolicy } = this.props

    this.onCloseConditions()

    const preparedConditionData = generateConditionForServer(conditionData)
    updatePolicyItem(pathToItem, parentPolicy, preparedConditionData)
  }

  render() {
    const { condition } = this.state
    const { policy, deletePolicyItem } = this.props
    const isTicketingAvailable = isTicketingEnabledFromSettings()
    const isPsaAvailable = window.psaConnect.isTicketingAvailable()

    return (
      <>
        <EditorPortalModal show={this.state.showConditionModal}>
          <ConditionsModal
            {...{
              nodeRole: this.props.policy.nodeRole,
              onSaveCondition: this.onSaveCondition,
              onCloseModal: this.onCloseConditions,
              editing: condition.conditionUid,
              condition,
            }}
          />
        </EditorPortalModal>

        <Box
          {...{
            display: "grid",
            gridTemplateColumns: "4fr 1fr 1fr 2fr 1fr 1.5fr",
            gridGap: sizer(2),
            alignItems: "center",
            width: "100%",
            color: colors.ninjaBlack,
            fontWeight: 600,
            fontSize: sizer(3),
            padding: sizer(1, 3),
          }}
        >
          <Box>
            <span className="btn-link" onClick={this.handleAddCondition} key={localizationKey("Add a condition")}>
              {localized("Add a condition")}
            </span>
          </Box>
          <Box>{isTicketingAvailable && localized("Ticketing")}</Box>
          <Box>{isPsaAvailable && getPsaTypeLabel()}</Box>
          <Box>{localized("Channel(s)")}</Box>
          <Box>{localized("Notify Technicians")}</Box>
          <Box></Box>
        </Box>
        <ConditionsList
          {...{
            policy,
            deletePolicyItem,
            selectCondition: this.handleSelectedCondition,
            toggleEnabled: this.handleOnToggleEnabled,
          }}
        />
      </>
    )
  }
}

export default connect(
  ({ policyEditor }) => ({
    policy: policyEditor.policy,
    parentPolicy: policyEditor.parentPolicy,
  }),
  {
    updatePolicyItem,
    deletePolicyItem,
  },
)(Conditions)
