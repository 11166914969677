import { memo, useCallback, useEffect, useMemo, useRef } from "react"
import styled from "@emotion/styled"
import { reduce } from "ramda"

import { DateTimePicker, Modal, Select, Stepper, Text } from "@ninjaone/components"
import tokens from "@ninjaone/tokens"
import { parseAndValidateDate } from "@ninjaone/utils"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  isNinjaPSAEnabledFromSettings,
  isUserAllowedToUseNinjaPSAAdministrativeActions,
  validations,
  isValidInteger,
  sortByFieldNameCaseInsensitive,
} from "js/includes/common/utils"
import { useForm, useMountedState } from "js/includes/common/hooks"
import { getAgreements } from "js/includes/common/client"
import Loading from "js/includes/components/Loading"
import {
  agreementOriginTypes,
  getBillingOptions,
  locationOptions,
} from "js/includes/configuration/integrations/psa/psaProducts/productForm/formCommons"
import { getHoursAndMinutes } from "js/includes/ticketing/editor/shared/utils"
import { Box, Flex } from "js/includes/components/Styled"
import { StyledLabel } from "js/includes/components/Styled/Form"

const noneAgreementOptionValue = agreementOriginTypes.NONE
const useTicketAgreementOptionValue = agreementOriginTypes.TICKET

const usedAgreementOptions = [noneAgreementOptionValue, useTicketAgreementOptionValue]

const StyledTimerContainer = styled(Box)`
  display: grid;
  grid-template-columns: minmax(160px, 1fr) minmax(160px, 1fr) 1fr;
  grid-gap: ${tokens.spacing[4]};
`

const getAgreementCommonOptions = () => {
  return [
    { value: noneAgreementOptionValue, labelText: localized("None") },
    { value: useTicketAgreementOptionValue, labelText: localized("Use ticket agreement") },
  ]
}

const getAgreementValuesForOnChange = selectedAgreementValue => {
  return {
    agreementId: selectedAgreementValue,
    agreementOriginType: usedAgreementOptions.includes(selectedAgreementValue)
      ? selectedAgreementValue
      : agreementOriginTypes.SELF,
  }
}

const parseValuesForOnSetTime = values => {
  return {
    ...values,
    agreementId: values.agreementOriginType === agreementOriginTypes.SELF ? values.agreementId : null,
  }
}

const MAX_HOURS = 999
const MAX_MINUTES = 59

const validateTime = ({ max, value }) => {
  const min = 0
  const number = parseFloat(value)
  const isValidNumber = isValidInteger(number) && validations.isValidIntegerWithinRange(min, max, number).success

  return {
    success: isValidNumber,
    message: isValidNumber ? "" : localized("Value must be an integer between {{min}} and {{max}}", { min, max }),
  }
}

const successfulValidation = { success: true, message: "" }

const buildValidations = ({ originalHours }) => {
  return {
    minutes: value => {
      if (!value) {
        return successfulValidation
      }
      return validateTime({ max: MAX_MINUTES, value })
    },
    hours: value => {
      if (!value || parseFloat(value) === originalHours) {
        return successfulValidation
      }

      return validateTime({ max: MAX_HOURS, value })
    },
    startDate: value => {
      if (!value) return { success: false, message: localized("Date & time required") }
      const { error } = parseAndValidateDate({ value })

      return { success: !error, message: !!error ? localized("Invalid date") : "" }
    },
  }
}

export const TimerModal = memo(
  ({
    unmount,
    onSetTime,
    seconds,
    agreementOriginType: _agreementOriginType,
    agreementId: _agreementId,
    agreementName,
    clientId,
    startDate,
    billingType,
    remote,
    isReadOnly = false,
    isSaving = false,
  }) => {
    const [agreements, setAgreements] = useMountedState([])
    const [fetchingAgreements, setFetchingAgreements] = useMountedState(!!clientId)

    const originalAgreementIdRef = useRef(_agreementId)

    const agreementOriginType = _agreementOriginType || agreementOriginTypes.TICKET

    const agreementCommonOptions = useMemo(() => getAgreementCommonOptions(), [])

    const { agreementOptions } = useMemo(() => {
      const options = reduce(
        (options, { id, isActive, name }) => {
          if (isActive || id === originalAgreementIdRef.current) {
            const option = { value: id, labelText: name }
            options.push(option)
          }
          return options
        },
        [],
        agreements,
      )

      if (originalAgreementIdRef.current && !options.find(option => option.value === originalAgreementIdRef.current)) {
        const option = { value: originalAgreementIdRef.current, labelText: agreementName || localized("N/A") }
        options.push(option)
      }

      return {
        agreementOptions: [...agreementCommonOptions, ...sortByFieldNameCaseInsensitive("labelText", "ASC", options)],
      }
    }, [agreements, agreementName, agreementCommonOptions])

    const canViewPSA = useMemo(
      () => isNinjaPSAEnabledFromSettings() && isUserAllowedToUseNinjaPSAAdministrativeActions(),
      [],
    )
    const canViewSetAgreements = useMemo(() => canViewPSA && !!clientId, [canViewPSA, clientId])

    const { hours, minutes } = useMemo(() => getHoursAndMinutes(seconds || 0), [seconds])

    const { values, validation, validateForm, onChange } = useForm({
      fields: {
        hours,
        minutes,
        billingType,
        remote,
        startDate,
        agreementOriginType,
        agreementId:
          agreementOriginType === agreementOriginTypes.SELF && _agreementId ? _agreementId : agreementOriginType,
      },
      validate: buildValidations({ originalHours: hours }),
    })

    const dateValue = useMemo(() => (values.startDate ? new Date(values.startDate) : null), [values.startDate])

    const fetchAgreements = useCallback(async () => {
      try {
        if (canViewSetAgreements) {
          const agreementsResponse = await getAgreements({ clientId, active: true })
          setAgreements(agreementsResponse)
        }
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching Agreements"))
      } finally {
        setFetchingAgreements(false)
      }
    }, [clientId, setFetchingAgreements, setAgreements, canViewSetAgreements])

    useEffect(fetchAgreements, [fetchAgreements])

    const handleSave = () => {
      if (!validateForm()) {
        return
      }
      onSetTime?.(parseValuesForOnSetTime(values))
      unmount?.()
    }

    const onAgreementSelected = selection => {
      onChange(getAgreementValuesForOnChange(selection))
    }

    return (
      <Modal
        id="timer-editor-modal"
        unmount={unmount}
        size="sm"
        cancelable
        titleGroup={{ titleText: isReadOnly ? localized("View time") : localized("Edit time") }}
        buttons={[
          ...(isReadOnly
            ? []
            : [
                {
                  type: "save",
                  labelText: localized("Apply"),
                  disabled: fetchingAgreements || isSaving,
                  onClick: handleSave,
                },
              ]),
        ]}
      >
        <Flex flexDirection="column" gap={tokens.spacing[2]}>
          <Flex flexDirection="column">
            <StyledLabel htmlFor="timer-start-date">
              <Text type="body" color="colorTextStrong">
                {localized("Start date & time")}
              </Text>
            </StyledLabel>
            <DateTimePicker
              id="timer-start-date"
              disabled={isReadOnly}
              selectedDate={dateValue}
              selectedTime={dateValue}
              errorMessage={validation.message.startDate}
              onDateTimeChange={startDate => onChange("startDate", startDate?.valueOf())}
            />
          </Flex>

          <StyledTimerContainer>
            <Flex flexDirection="column">
              <Stepper
                labelText={localized("Hours")}
                disabled={isReadOnly}
                value={values.hours}
                min={0}
                max={MAX_HOURS}
                step={1}
                onChange={value => onChange("hours", value)}
                error={validation.message.hours}
              />
              <span>{validation.message.hours}</span>
            </Flex>
            <Flex flexDirection="column">
              <Stepper
                labelText={localized("Minutes")}
                disabled={isReadOnly}
                value={values.minutes}
                min={0}
                max={MAX_MINUTES}
                step={1}
                onChange={value => onChange("minutes", value)}
                error={validation.message.minutes}
              />
            </Flex>
          </StyledTimerContainer>

          {canViewSetAgreements && (
            <Flex flexDirection="column">
              {fetchingAgreements ? (
                <Box height="35px">
                  <Loading />
                </Box>
              ) : (
                <Select
                  labelId={"timer-agreement"}
                  labelText={localized("Agreement")}
                  value={values.agreementId}
                  options={agreementOptions}
                  onChange={onAgreementSelected}
                  disabled={isReadOnly}
                />
              )}
            </Flex>
          )}

          {canViewPSA && (
            <Flex flexDirection="column">
              <Select
                labelId={"timer-billing-type"}
                labelText={localized("Billing")}
                value={values.billingType}
                options={getBillingOptions()}
                onChange={selection => onChange("billingType", selection)}
                disabled={isReadOnly}
              />
            </Flex>
          )}

          {canViewPSA && (
            <Flex flexDirection="column">
              <Select
                labelId={"timer-work-performed"}
                labelText={localized("Labor rate")}
                value={values.remote}
                options={locationOptions}
                onChange={selection => onChange("remote", selection)}
                disabled={isReadOnly}
              />
            </Flex>
          )}
        </Flex>
      </Modal>
    )
  },
)
