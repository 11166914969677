import { createClient } from "urql"
import qs from "qs"

import type { TagDetails } from "js/includes/common/types"
import { fetch, fetchJson } from "js/includes/common/utils"
import { getTicketingUrl } from "js/includes/common/utils"
import {
  ticketRuleById,
  ticketRulesetOptions,
} from "js/includes/configuration/integrations/ticketing/ticketAutomation/ticketRuleset/graphql"
import { getTags, ticketByIdAfterUpdate, ticketLogEntryById } from "js/includes/ticketing/graphql"

type FieldUsageDetails = { formCount: number, boardCount: number, automationCount: number }

export const graphqlTicketingClient = () =>
  createClient({
    url: getTicketingUrl(),
  })

export async function deleteTrigger(id) {
  await fetchJson(`/ticketing/trigger/${id}`, {
    options: {
      method: "DELETE",
    },
  })
}

export function getBoardTriggers() {
  return fetchJson("/ticketing/trigger?type=BOARD")
}

export function getTicketingAutomationConfigurations() {
  return fetchJson("/ticketing/automation")
}

export const approveEmails = pendingEmailIds =>
  fetchJson(`/ticketing/pending-email/approve`, {
    options: { method: "POST", body: JSON.stringify(pendingEmailIds) },
  })

export const rejectEmails = pendingEmailIds =>
  fetchJson(`/ticketing/pending-email/reject`, {
    options: { method: "POST", body: JSON.stringify(pendingEmailIds) },
  })

export const approveEmail = (pendingEmailId, action) =>
  fetchJson(`/ticketing/pending-email/${pendingEmailId}/approve`, {
    options: { method: "POST", body: JSON.stringify({ ...(action && { action }) }) },
  })

export const rejectEmail = (pendingEmailId, action) =>
  fetchJson(`/ticketing/pending-email/${pendingEmailId}/reject`, {
    options: { method: "POST", body: JSON.stringify({ ...(action && { action }) }) },
  })

export const getTicketingForms = () => fetchJson("/ticketing/form")

export const getTicketingFormById = formId => fetchJson(`/ticketing/form/${formId}`)

export const fetchTicketLogEntriesPaginated = (
  ticketId,
  {
    publicComments = true,
    privateComments = true,
    systemActivities = true,
    changelog = true,
    sortDirection = "DESC",
    anchorId,
    pageSize = 50,
  },
) => {
  const queryString = qs.stringify(
    {
      publicComments,
      privateComments,
      systemActivities,
      changelog,
      sortDirection,
      anchorId,
      pageSize,
    },
    { addQueryPrefix: true, skipNulls: true },
  )
  return fetchJson(`/ticketing/log-entry/ticket/${ticketId}/log-entries${queryString}`)
}
export const getTicketById = ticketId => fetchJson(`/ticketing/ticket/${ticketId}`)

export const getTicketByIdForSplitting = ticketId => fetchJson(`/ticketing/ticket/minimal/${ticketId}`)

export const getTicketLogEntryByIdForTooltip = ticketLogEntryId =>
  graphqlTicketingClient()
    .query(ticketLogEntryById, { ticketLogEntryId }, { requestPolicy: "network-only" })
    .toPromise()

export const getTicketRuleById = id =>
  graphqlTicketingClient()
    .query(ticketRuleById, { id }, { requestPolicy: "network-only" })
    .toPromise()

export const getTicketRulesetOptions = id =>
  graphqlTicketingClient()
    .query(ticketRulesetOptions, {}, { requestPolicy: "network-only" })
    .toPromise()

export const updateTicket = (payload, ticketId) =>
  fetchJson(`/ticketing/ticket/${ticketId}`, {
    options: {
      method: "PUT",
      body: JSON.stringify(payload),
    },
  })

export const splitTicket = (payload, ticketId) => {
  return fetchJson(`/ticketing/ticket/${ticketId}/split`, {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}

export const mergeTicket = payload => {
  return fetchJson("/ticketing/ticket/merge", {
    options: {
      method: "POST",
      body: JSON.stringify(payload),
    },
  })
}

export const deleteTickets = ticketIds =>
  fetchJson("/ticketing/ticket/permanent-delete", {
    options: {
      method: "POST",
      body: JSON.stringify(ticketIds),
    },
  })

export async function getTicketIncidents(id) {
  return fetchJson(`/ticketing/ticket/${id}/incident`)
}

export const attachmentUploadUrl = "/attachments/tmp/upload-file/ticket"

export const getDeleteUrl = resourceId => {
  return `/attachments/tmp/delete-file/ticket?fileName=${resourceId}`
}

export const makeConditionRuleDefault = conditionRuleId => {
  return fetchJson(`/ticketing/ruleset/condition-rule/${conditionRuleId}/default`, {
    options: { method: "POST" },
  })
}

export const makeScriptRuleDefault = scriptRuleId => {
  return fetchJson(`/ticketing/ruleset/script-rule/${scriptRuleId}/default`, {
    options: { method: "POST" },
  })
}

export const makeActivityRuleDefault = activityRuleId => {
  return fetchJson(`/ticketing/ruleset/activity-rule/${activityRuleId}/default`, {
    options: { method: "POST" },
  })
}

export const getBoardTicketListPaginated = (boardId, options = {}) => {
  return fetchJson(`/ticketing/trigger/board/${boardId}/run/page`, {
    options: {
      method: "POST",
      body: JSON.stringify(options),
    },
  })
}

export const getTicketAfterUpdate = ticketId =>
  graphqlTicketingClient()
    .query(ticketByIdAfterUpdate, { id: ticketId }, { requestPolicy: "network-only" })
    .toPromise()

export const getTicketTags = () =>
  graphqlTicketingClient()
    .query(getTags, {}, { requestPolicy: "network-only" })
    .toPromise()

export const getClientDashboardMetrics = async clientId => {
  const [firstTimeResponse, oneTouchResolution] = await Promise.all([
    fetchJson(`/ticketing/dashboard/client-dashboard/${clientId}/first-time-response`),
    fetchJson(`/ticketing/dashboard/client-dashboard/${clientId}/one-touch-resolution`),
  ])

  return { firstTimeResponse, oneTouchResolution }
}

export const getDeviceDashboardMetrics = async deviceId => {
  const [firstTimeResponse, oneTouchResolution] = await Promise.all([
    fetchJson(`/ticketing/dashboard/device-dashboard/${deviceId}/first-time-response`),
    fetchJson(`/ticketing/dashboard/device-dashboard/${deviceId}/one-touch-resolution`),
  ])

  return { firstTimeResponse, oneTouchResolution }
}

export const getTicketAttributeUsage = (attributeId: number): Promise<FieldUsageDetails> =>
  fetchJson(`/ticketing/attribute/${attributeId}/usage-counts`)

export const getTagDetails = (): Promise<TagDetails[]> => fetchJson("/ticketing/tags")

export const renameTag = (data: { name: string, newName: string }) =>
  fetchJson("/ticketing/tags/rename", {
    options: {
      method: "PATCH",
      body: JSON.stringify(data),
    },
  })

export const createTag = name =>
  fetchJson("/ticketing/tags", {
    options: {
      method: "POST",
      body: JSON.stringify({ name }),
    },
  })

export const mergeTags = (sourceTags, targetTag) =>
  fetchJson("/ticketing/tags/merge", {
    options: {
      method: "PATCH",
      body: JSON.stringify({
        sourceTags,
        targetTag,
      }),
    },
  })

export const deleteTag = name =>
  fetchJson(`/ticketing/tags/${encodeURIComponent(name)}`, {
    options: {
      method: "DELETE",
    },
  })

export const getTagsUsedInTickets = ticketIds =>
  fetchJson("/ticketing/tags/by-tickets", {
    options: {
      method: "POST",
      body: JSON.stringify(ticketIds),
    },
  })

export const reinstateTickets = ticketIds =>
  fetchJson("/ticketing/ticket/reinstate", {
    options: {
      method: "POST",
      body: JSON.stringify(ticketIds),
    },
  })

export const getTicketIdByNodeAndJob = async ({ nodeId, jobUid }) => {
  const response = await fetch(`/ticketing/ticket/node/${nodeId}/job/${jobUid}`)
  const ticketId = await response.text()
  return ticketId ? parseInt(ticketId, 10) : null
}

export const setBoardsOrder = request =>
  fetchJson("/ticketing/trigger/boards/order", {
    options: {
      method: "PATCH",
      body: JSON.stringify(request),
    },
  })

export const updateBoardsConfiguration = ({ numberOfVisibleBoards }) =>
  fetchJson("/ticketing/trigger-configuration/BOARD", {
    options: {
      method: "PUT",
      body: JSON.stringify({ numberOfVisibleBoards }),
    },
  })

export const getBoardsConfiguration = () => fetchJson("/ticketing/trigger-configuration/BOARD")

//Custom Status client methods
export const createCustomStatus = status =>
  fetchJson("/ticketing/status", {
    options: {
      method: "POST",
      body: JSON.stringify(status),
    },
  })

export const updateCustomStatus = ({ id, displayName, content }) =>
  fetchJson(`/ticketing/status/${id}`, {
    options: {
      method: "PUT",
      body: JSON.stringify({ displayName, content }),
    },
  })

export const deleteCustomStatus = statusId =>
  fetchJson(`/ticketing/status/${statusId}`, { options: { method: "DELETE" } })

export const getAllStatuses = () => fetchJson("/ticketing/status")
export const getAllStatusesForEndUser = () => fetchJson("/ticketing/end-user/status")

export const getClosedStatuses = () => fetchJson("/ticketing/status/closed")

export const getLastTicketId = () => fetchJson("/ticketing/ticket/last-ticket-id")

export const validateTickets = ({ ticketIds }) =>
  fetchJson("/ticketing/ticket/validate", {
    options: {
      method: "POST",
      body: JSON.stringify({
        ids: ticketIds,
        validations: ["FORM_REQUIRED_FIELDS"],
      }),
    },
  })

export const getRecentTicketsListPaginated = ({
  organizationId,
  locationId,
  nodeId,
  requesterUid,
  beforeDate,
  afterDate,
  sortBy,
  sortDirection,
  pageIndex,
  pageSize,
}) => {
  const queryString = qs.stringify(
    {
      organizationId,
      locationId,
      nodeId,
      requesterUid,
      beforeDate,
      afterDate,
      sortBy,
      sortDirection,
      pageIndex,
      pageSize,
    },
    { addQueryPrefix: true, skipNulls: true },
  )
  return fetchJson(`/ticketing/ticket/tickets${queryString}`)
}

/**
 * Updates the agreement used in a set of tickets.
 * @param {object} args. An object like {clientId, ticketIds, agreementId}.
 * When clientId is passed, ticketIds field is ignored and the agreement is set in all tickets of the organization.
 * ticketIds should be passed when clientId is not.
 * @returns and array with ids of the tickets updated.
 */
export const updateAgreementOfTickets = ({ clientId, ticketIds, agreementId }) =>
  fetchJson("/ticketing/ninja-psa/agreement", {
    options: {
      method: "PUT",
      body: JSON.stringify({
        ...(clientId && { clientId }),
        ...(!clientId && { ticketIds }),
        agreementId,
      }),
    },
  })

export const fetchTicketTooltips = ticketIds =>
  fetchJson("/ticketing/ticket/tooltip", {
    options: {
      method: "POST",
      body: JSON.stringify({ ticketIds }),
    },
  })
