import styled from "@emotion/styled"
import { typography } from "@ninjaone/tokens"
import { sizer, getColorFromProps } from "@ninjaone/utils"

export const getTabListStyles = ({ theme }) => `
  display: grid;

  grid-auto-flow: column;
  grid-auto-columns: min-content;

  position: relative;
  min-height: 0px;

  background-color: ${theme.colorBackground};

  &.wrapped-tabs {
    display: flex;
    flex-wrap: wrap;
  }

  &:after {
    content: "";
    
    position: absolute;
    pointer-events: none;

    left: 0;
    right: 0;
    bottom: 0;

    height: 1px;
    background-color: ${theme.colorBorderWeakest};
  }
`

export const StyledGrid = styled.div`
  min-width: 1024px;
  max-width: 1680px;
  flex: 1;
  display: grid;
  margin: 0 auto;
  height: 100%;
  padding: ${sizer(0, 4)};
  grid-column-gap: ${sizer(4)};
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: ${sizer(10)};
  grid-template-rows: ${sizer(13, "minmax(0, 1fr)")};
`

export const StyledHr = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  background-color: ${getColorFromProps({ defaultTo: "gray" })};
  margin: ${({ margin }) => margin ?? 0};
`

export const StyledButtonLink = styled.button`
  ${({ disabled }) => !disabled && `cursor: pointer;`}
  user-select: none;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colorTextAction};
  width: ${({ width = "auto" }) => width};
  text-align: ${({ textAlign = "left" }) => textAlign};
  font-weight: ${({ fontWeight }) => fontWeight ?? typography.fontWeight.regular};
`

export const resetButtonStyle = `
  all: unset;
  outline: revert;
  cursor: pointer;
`

export const fixDragPreviewOverlappingContentStyle = `
  // fixes an issue where any overlapping content appears in the drag and drop preview
  transform: translate(0, 0);
`

export const UnstyledButton = styled.button`
  ${resetButtonStyle}
`

export * from "./Editor"
export * from "./Table"
export * from "./Input"
