import React, { memo, useEffect } from "react"
import { always, cond, equals, includes, startsWith, T } from "ramda"
import { Link, NavLink, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"
import { OpenArrowIcon } from "@ninjaone/icons"
import { getTextSize, sizer } from "@ninjaone/utils"
import { Text } from "@ninjaone/components"
import { color } from "@ninjaone/themes/base"
import { Box, Flex } from "js/includes/components/Styled"
import { useMountedState } from "js/includes/common/hooks"
import { getAdministrationNavItems } from "js/includes/configuration/utils"
import { containsAny, localized } from "js/includes/common/utils"

const StyledNav = styled.nav`
  background-color: white;
  width: 300px;
  height: 100%;
  overflow: auto;
  flex: 0 0 auto;
  border-right: 1px solid ${({ theme }) => theme.color.divider};
  & a {
    color: ${({ theme }) => theme.color.black["100"]};
    font-size: ${getTextSize("md")};
    font-weight: 500;
  }
`

const StyledNavLink = styled(NavLink)`
  width: 100%;
  cursor: pointer;
  padding: ${sizer(4, 4, 4, 6)};
`

const StyledIconButton = styled.button`
  display: flex;
  border: none;
  background: none;
  padding: ${sizer(1)};
  cursor: pointer;
  margin-right: ${sizer(6)};
`

export const StyledSubtab = styled.div`
  padding: ${({ padding }) => padding ?? sizer(2, 6, 2, 9)};
  min-width: ${({ minWidth }) => minWidth};
  cursor: pointer;
  background-color: ${({ theme, isActive }) => isActive && theme.color.linkBackground};
  color: ${({ theme, isActive }) => isActive && theme.color.action};
  &:hover {
    background-color: ${({ theme }) => theme.color.linkBackground};
  }
`

const checkIfActiveTab = (tab, location) => {
  const excludedTabs = tab.excludedTabs ?? []
  const baseRoute = tab.baseRoute ?? null

  return cond([
    [equals(tab.route), always(true)],
    [path => tab.aliases && tab.aliases.some(alias => !!path.match(alias)), T],
    [containsAny(excludedTabs), always(false)],
    [() => baseRoute && startsWith(baseRoute)(location.pathname), always(true)],
    [includes(tab.route), always(true)],
    [T, always(false)],
  ])(location.pathname)
}

function AdministrationTabs({ divisionConfig }) {
  return (
    <StyledNav ariaLabel={localized("Administration navigation")}>
      {getAdministrationNavItems({ divisionConfig }).map(
        ({ to, isActive, name, showWhen = true, isBeta, subtabs, baseUrl }) => (
          <MenuItem {...{ to, isActive, name, showWhen, isBeta, subtabs, baseUrl }} key={name} />
        ),
      )}
    </StyledNav>
  )
}

const MenuItem = memo(({ to, isActive, name, showWhen = true, isBeta, subtabs, baseUrl }) => {
  const [showDropdown, setShowDropdown] = useMountedState(false)
  const location = useLocation()
  const theme = useTheme()

  useEffect(() => {
    if ((baseUrl && startsWith(baseUrl)(location.pathname)) || startsWith(to)(location.pathname)) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }, [to, setShowDropdown, location, baseUrl])

  return showWhen ? (
    <Box borderBottom={`1px solid ${color.divider}`} width="100%" data-test-wrapper="subtabs-group-wrapper">
      <Flex justifyContent="space-between" alignItems="center">
        <StyledNavLink {...{ key: to, to, isActive }} data-test-subtab="subtab-title">
          <Text
            size="md"
            bold
            color={!subtabs && equals(to)(location.pathname) ? theme.color.action : theme.color.black["100"]}
          >
            {name}
          </Text>
        </StyledNavLink>
        {!!subtabs?.length && (
          <StyledIconButton data-test-icons="open-arrow" onClick={() => setShowDropdown(!showDropdown)}>
            <OpenArrowIcon inverse={showDropdown} />
          </StyledIconButton>
        )}
      </Flex>
      {showDropdown && !!subtabs?.length && (
        <Box paddingBottom={sizer(3)}>
          {subtabs.map(({ route, name, isBeta, show = true, ...tab }) => {
            return show ? (
              <Link to={route} key={`tab-${route}`} data-test-subtab="subtab-links">
                <StyledSubtab isActive={checkIfActiveTab({ route, ...tab }, location)}>
                  <Text size="sm">
                    {name}
                    {isBeta && <sup>Beta</sup>}
                  </Text>
                </StyledSubtab>
              </Link>
            ) : null
          })}
        </Box>
      )}
    </Box>
  ) : null
})

export default connect(({ session }) => ({
  divisionConfig: session.divisionConfig,
}))(AdministrationTabs)
