import _ from "underscore"
import Backbone from "backbone"
import { fetchJson, showErrorMessage, localized, isFeatureEnabled } from "js/includes/common/utils"
import { NinjaResponseError } from "js/includes/common/types"
import { isGranted } from "js/includes/common/services/userPermissions"

export default Backbone.Model.extend({
  // The idAttribute is necessary for DELETE requests to be made
  idAttribute: "psaType",

  // We can't use a urlRoot. This is to prevent the idAttribute getting appeded to the url upon a DELETE.
  url: "/divisionconfig/psa",

  parse({ content, resultCode }) {
    if (resultCode === "SUCCESS") {
      return content || { psaType: null }
    } else {
      showErrorMessage(localized("Error getting PSA config"))
    }
  },

  initialize: function() {
    _.bindAll(this, "isAutotask", "isConnectWise", "isServiceNow", "isPresent", "isRepairShopr")
  },

  async save() {
    try {
      const response = await fetchJson(this.url, {
        options: {
          method: "PUT",
          body: JSON.stringify({ content: this.toJSON() }),
        },
      })
      if (response.resultCode !== "SUCCESS") {
        throw new NinjaResponseError(response)
      }
    } catch (error) {
      showErrorMessage(localized("Error updating PSA config"))
      throw error
    }
  },

  isPresent() {
    return this.has("psaType") && !!this.get("psaType")
  },

  isConnectWise() {
    return this.isPresent() && this.get("psaType") === "CONNECTWISE"
  },

  isAutotask() {
    return this.isPresent() && this.get("psaType") === "AUTOTASK"
  },

  isServiceNow() {
    return this.isPresent() && isFeatureEnabled("service_now") && this.get("psaType") === "SERVICE_NOW"
  },

  isZendesk() {
    return this.isPresent() && isFeatureEnabled("zendesk") && this.get("psaType") === "ZENDESK"
  },

  isRepairShopr() {
    return this.isPresent() && this.get("psaType") === "REPAIR_SHOPR"
  },

  isAccelo() {
    return this.isPresent() && this.get("psaType") === "ACCELO"
  },

  hasPSAAccess() {
    return isGranted({ entityType: "SYSTEM", permissionName: "Configuration.PSA.Access" })
  },

  isTicketingAvailable() {
    return this.isConnectWise() || this.isAutotask() || this.isServiceNow() || this.isZendesk()
  },

  isEnabled() {
    return this.get("enabled")
  },
})
