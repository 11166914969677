import { localized } from "js/includes/common/utils"
import { map, compose, when, prepend, applySpec, identity } from "ramda"

export const getNoneOption = ({ valueKey = "value", labelKey = "label" } = {}) => ({
  [valueKey]: null,
  [labelKey]: localized("None"),
})

export const getTimeOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: "MINUTES", label: localized("Minutes") },
  { value: "HOURS", label: localized("Hours") },
  { value: "DAYS", label: localized("Days") },
]

export const getRetriggerOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: true, label: localized("Create new ticket") },
  { value: false, label: localized("Append to existing ticket (if not closed)") },
]

export const getTypeOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: "PROBLEM", label: localized("Problem") },
  { value: "QUESTION", label: localized("Question") },
  { value: "INCIDENT", label: localized("Incident") },
  { value: "TASK", label: localized("Task") },
]

export const getSourceOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: "ACTIVITY", label: localized("Activity") },
  { value: "CONDITION", label: localized("Condition") },
  { value: "EMAIL", label: localized("Email") },
  { value: "END_USER", label: localized("End User") },
  { value: "HELP_REQUEST", label: localized("Systray Help Request") },
  { value: "SCHEDULED_SCRIPT", label: localized("Scheduled Automation") },
  { value: "TECHNICIAN", label: localized("Technician") },
  // { value: "WEB_FORM", label: localized("Web Form") },
]

export const getSeverityOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: "CRITICAL", label: localized("Critical") },
  { value: "MAJOR", label: localized("Major") },
  { value: "MODERATE", label: localized("Moderate") },
  { value: "MINOR", label: localized("Minor") },
]

export const getPriorityOptions = (showNone = false) => [
  ...(showNone ? [getNoneOption()] : []),
  { value: "HIGH", label: localized("High") },
  { value: "MEDIUM", label: localized("Medium") },
  { value: "LOW", label: localized("Low") },
]

export const getCommentTypeOptions = () => [
  { value: "PRIVATE", label: localized("Private") },
  { value: "PUBLIC", label: localized("Public") },
]

export const getUpdatedByOptions = () => [
  { value: "REQUESTER", label: localized("Requester") },
  { value: "ASSIGNEE", label: localized("Assignee") },
  { value: "CC", label: localized("CC") },
  { value: "EXTERNAL_CONTACT", label: localized("External Contact") },
  { value: "INTERNAL_USER", label: localized("Internal User") },
]

export const getEmailOptions = map(
  applySpec({
    label: identity,
    value: identity,
  }),
)

export const getUserOptions = (users, key = "uid", showNone = false) => {
  return compose(
    when(() => showNone, prepend(getNoneOption())),
    map(user => ({
      value: user[key],
      label: user.firstName ? `${user.firstName} ${user.lastName}` : user.email,
      email: user.email,
    })),
  )(users || [])
}

export const getOptions = (list, key = "id", showNone = false) => {
  return compose(
    when(() => showNone, prepend(getNoneOption())),
    map(listItem => ({
      ...listItem,
      value: listItem[key],
      label: listItem.name,
    })),
  )(list || [])
}
