import { cond, equals, always, T } from "ramda"
import { localizationKey } from "./ssrAndWebUtils"

export const isPsaEnabled = () => window.psaConnect.isTicketingAvailable()
export const isPsaAutotask = () => window.psaConnect.isAutotask()
export const isPsaZendesk = () => window.psaConnect.isZendesk()
export const canUserManageEnabledPsaIntegration = () => isPsaEnabled() && window.psaConnect.isEnabled()

export const getPsaTypeLabel = () =>
  cond([
    [equals("CONNECTWISE"), always("ConnectWise")],
    [equals("AUTOTASK"), always("Autotask")],
    [equals("REPAIR_SHOPR"), always("RepairShopr")],
    [equals("ACCELO"), always("Accelo")],
    [equals("SERVICE_NOW"), always("ServiceNow")],
    [equals("ZENDESK"), always("Zendesk")],
    [
      T,
      () => {
        throw new Error("type is not supported")
      },
    ],
  ])(window.psaConnect.get("psaType"))

export const getTicketingTemplatesOptions = parseId =>
  [{ labelToken: localizationKey("Default ticket template"), value: "NONE" }].concat(
    window.psaTicketTemplateList.models.map(ticketTemplate => ({
      labelText: ticketTemplate.get("name"),
      value: parseId ? ticketTemplate.get("id").toString() : ticketTemplate.get("id"),
    })),
  )
