import { pick, compose, join, props, match, trim, either, prop } from "ramda"
import { getUserType } from "js/state/selectors/user"

/**
 * @example
 * const input = { firstName: "John", lastName: "Doe" }
 * fullName(input) // "John Doe"
 */
export const fullName = compose(trim, join(" "), props(["firstName", "lastName"]))

export const fullNameOrEmail = either(fullName, prop("email"))

export const initials = compose(join(""), match(/\b(\w)/g), fullName)

export const firstTwoInitials = person =>
  (person.firstName?.[0].toUpperCase() ?? "") + (person.lastName?.[0].toUpperCase() ?? "")

export const createConsentObject = (
  date = Math.floor(Date.now() / 1000),
  a1value = true,
  m1m2value = true,
  a2value = true,
  p1value = true,
) => ({
  a1: {
    value: a1value,
    timestamp: date,
  },
  m1m2: {
    value: m1m2value,
    timestamp: date,
  },
  a2: {
    value: a2value,
    timestamp: date,
  },
  p1: {
    value: p1value,
    timestamp: date,
  },
})

export const userIsTechnician = () => {
  const userType = getUserType(window.store.getState().user) ?? window.application?.get("user")?.get("userType")
  return userType === "TECHNICIAN"
}

export const pickMfaProps = pick(["mfaType", "available_mfa", "credsRequest", "loginToken"])

export const getPhoneLastDigits = phoneNumber => {
  const phoneNumberLength = phoneNumber.length

  if (phoneNumberLength === 4) {
    return phoneNumber
  }

  return phoneNumber.substring(phoneNumberLength - 4, phoneNumberLength)
}

export const isNinja = userEmail => {
  const ninjaDomains = ["@ninjamsp.com", "@ninjarmm.com", "@msp.ninja", "@ninjaone.com"]
  return ninjaDomains.some(ninjaDomain => userEmail.endsWith(ninjaDomain))
}

export const defaultUser = {
  content: {
    notification: {
      clientList: [],
    },
    onboardingPerformed: false,
    language: "browser-lang",
    userInactivityTimeout: 240,
    gettingStartedSteps: {
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      stepFour: false,
      stepFive: false,
      stepSix: false,
      finishTraining: false,
    },
    email_notification: {
      enabled: false,
      minPriority: null,
      minSeverity: null,
      schedule: {
        weekDays: [],
        startHour: 0,
        endHour: 0,
      },
    },
    sms_notification: {
      enabled: false,
      minPriority: null,
      minSeverity: null,
      schedule: {
        weekDays: [],
        startHour: 0,
        endHour: 0,
      },
    },
  },
  enabled: true,
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  authenticationType: "NATIVE",
  mfaType: "NONE",
  notifyAllClients: true,
  permitAllClients: true,
  sysadmin: false,
  roles: [],
  permissions: [],
  promotions: [],
  isEditable: true,
}

export const getUserRolesIdsBySource = (rolesReferences = []) => {
  const nativeRoles = []
  const scimRoles = []

  rolesReferences.forEach(({ roleId, source }) => {
    if (source === "NATIVE") nativeRoles.push(roleId)
    else if (source === "SCIM") scimRoles.push(roleId)
  })

  return { nativeRoles, scimRoles }
}
