/** @jsxImportSource @emotion/react */
import { memo } from "react"
import { find } from "ramda"
import { Checkbox } from "js/includes/components/NinjaReactICheck"
import { localized, isTicketingEnabledFromSettings } from "js/includes/common/utils"
import { generatedDescription } from "js/includes/editors/Policy/PolicyEditor/tabs/Conditions/helpers"
import Inheritable from "js/includes/editors/Policy/PolicyEditor/ReduxInheritable"
import { TicketingOptions } from "js/includes/common/_options"
import { ConditionRuleset } from "./ConditionRuleset"
import styled from "@emotion/styled/macro"
import { Box } from "js/includes/components/Styled"
import { sizer } from "@ninjaone/utils"
import { colors } from "js/includes/common/theme"
import NotificationChannelSummary from "js/includes/editors/Policy/Models/General/NotificationChannelSummary"
import Loading from "js/includes/components/Loading"

const StyledRow = styled(Box)`
  cursor: pointer;
  border-bottom: 1px solid #e1e2e4;

  &:hover {
    background: ${colors.ninjaWhite};
    .action-links {
      display: block;
    }
  }
  .action-links {
    display: none;
  }
  .inheritance-status {
    margin: 0 auto !important;
  }
`

const getTicketingValue = ticketingAction =>
  find(option => option.value === ticketingAction, TicketingOptions.items).text()

const getTicketingTemplate = templateId => {
  return templateId ? window.psaTicketTemplateList.get(templateId).get("name") : localized("Default ticket template")
}

const ConditionListItem = memo(
  ({ inheritableItem, onSelect, onDelete, onToggleEnabled, inheritanceComponent, loadingChannelList, channelList }) => {
    function handleDelete(e) {
      e.stopPropagation()
      onDelete()
    }

    const isTicketingAvailable = window.psaConnect.isTicketingAvailable()

    return (
      <StyledRow
        display="grid"
        gridTemplateColumns="4fr 1fr 1fr 2fr 1fr 1.5fr"
        gridGap={sizer(2)}
        padding={sizer(4, 3)}
        alignItems="center"
        width="100%"
        onClick={onSelect}
        as="li"
      >
        <Box className="flex-row spacing" alignItems="center">
          <Checkbox
            checkboxClass="icheckbox_square-blue"
            increaseArea="20%"
            checked={inheritableItem.enabled}
            onChange={onToggleEnabled}
          />
          <Box flex="1">
            <h4>{inheritableItem.displayName || inheritableItem.conditionName}</h4>
            <span>{generatedDescription(inheritableItem)}</span>
          </Box>
        </Box>

        <Box>
          {isTicketingEnabledFromSettings() && inheritableItem.createNinjaTicket && (
            <ConditionRuleset ticketRulesetId={inheritableItem.ticketRulesetId} />
          )}
        </Box>

        <Box>
          {isTicketingAvailable && inheritableItem.ticketingAction !== "NONE" && (
            <span>
              <div>{getTicketingValue(inheritableItem.ticketingAction)}</div>
              <div className="small">{getTicketingTemplate(inheritableItem.ticketTemplateId)}</div>
            </span>
          )}
        </Box>

        {loadingChannelList ? (
          <Loading />
        ) : (
          <NotificationChannelSummary
            {...{
              channelIds: inheritableItem.channels || [],
              channelList,
              maxTextLength: 50,
            }}
          />
        )}

        <Box>
          <span>{inheritableItem.notificationAction === "SEND" ? localized("Yes") : localized("No")}</span>
        </Box>

        {inheritanceComponent || (
          <Box className="action-links" textAlign="right">
            <span className="btn-link text-danger" onClick={handleDelete}>
              {localized("Delete")}
            </span>
          </Box>
        )}
      </StyledRow>
    )
  },
)

export default Inheritable(ConditionListItem)
