import { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { localized } from "js/includes/common/utils"
import { Box } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"
import Tooltip from "js/includes/components/Tooltip"

const MultiTextInput = memo(({ labelToken, tooltipToken, placeholderToken, values, onChange, prop }) => (
  <FormGroup controlId={prop} validationState={values.errors && values?.errors?.message?.[prop] ? "error" : null}>
    <Col componentClass={ControlLabel} sm={4}>
      {localized(labelToken)}
      {tooltipToken && (
        <Box className="two-rows-ellipsis" marginLeft="2px" display="inline">
          <Tooltip token={tooltipToken}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </Box>
      )}
    </Col>
    <Col sm={8}>
      <SearchableDropDown
        isMulti
        isCreatable
        options={values[prop]}
        value={values[prop]}
        onSelect={values => {
          onChange(prop, values)
        }}
        width="100%"
        searchPlaceholderToken={placeholderToken}
        validationState={values?.errors?.message?.[prop] ? "error" : null}
      />
      {values?.errors?.message?.[prop] && <em className="invalid m-t-none"> {values.errors.message[prop]}</em>}
    </Col>
  </FormGroup>
))

export default MultiTextInput
