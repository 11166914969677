import React, { memo } from "react"
import { FormGroup, Col, ControlLabel } from "react-bootstrap"
import { localized, localizationKey } from "js/includes/common/utils/ssrAndWebUtils"
import NumberStepper from "js/includes/editors/Policy/PolicyEditor/formComponents/NumberStepper"

const DurationStepper = memo(
  ({
    values,
    onChange,
    valueKey = "duration",
    labelToken = localizationKey("Duration"),
    durationToken = localizationKey("Minutes"),
    maxValue = 60,
    minValue = 1,
    stepper = 1,
  }) => (
    <FormGroup controlId="durationStepper">
      <Col componentClass={ControlLabel} sm={4}>
        {`${localized(labelToken)} (${localized(durationToken)})`}
      </Col>
      <Col sm={8}>
        <NumberStepper
          minValue={minValue}
          maxValue={maxValue}
          initialValue={values?.[valueKey]}
          stepper={stepper}
          onChange={value => onChange(valueKey, parseInt(value))}
        />
      </Col>
    </FormGroup>
  ),
)

export default DurationStepper
