import { find, propEq, reduce, mergeLeft, map } from "ramda"
import { mapRequesterToAppUser } from "js/includes/ticketing/shared/utils"

export const mapCCUsersToAppUsers = map(mapRequesterToAppUser)

export const getDefaultValueForType = type => {
  switch (type) {
    case "TEXT":
    case "TEXT_MULTILINE":
      return ""
    case "CHECKBOX":
      return false
    case "MULTI_SELECT":
    case "DROPDOWN":
    case "NUMERIC":
    case "DECIMAL":
    case "DATE":
    case "CLIENT":
    case "LOCATION":
    case "ASSIGNEE":
    case "DEVICE":
    case "TYPE":
    case "SEVERITY":
    case "PRIORITY":
    case "REQUESTER":
    default:
      return null
  }
}

export const findDefault = find(propEq("isDefault", true))

export const getAttributeInitialValues = reduce((acc, attribute) => {
  const { id, attributeType } = attribute
  const value = getDefaultValueForType(attributeType)
  return {
    ...acc,
    [id]: { attributeId: id, value },
  }
}, {})

export const generateTicketTimeEntryFields = (ticket = {}) => {
  const { description: { ticketTimeEntry } = {} } = ticket

  return ticketTimeEntry
}

export const generateTicketFormFields = (ticket, ticketForm) => {
  const {
    agreement,
    assignedAppUser,
    client,
    location,
    attributeValues,
    ccList,
    ccListUsers = [],
    ccUsers = [],
    node,
    priority,
    requester,
    severity,
    status,
    summary,
    tags,
    ticketFormId,
    type,
    version,
    parentTicket,
  } = ticket

  const attributeValuesForForm = getAttributeInitialValues(ticketForm?.fields ?? [])

  return {
    agreement,
    assignedAppUser,
    assignedAppUserId: assignedAppUser?.id ?? ticket?.assignedAppUserId ?? null,
    client,
    clientId: client?.id ?? ticket?.clientId ?? null,
    location,
    locationId: location?.id ?? ticket?.locationId ?? null,
    ccList,
    ccListUsers,
    ccUsers: mapCCUsersToAppUsers(ccUsers),
    node,
    priority,
    requester,
    severity,
    status,
    summary,
    tags,
    ticketFormId,
    type,
    version,
    parentTicketId: parentTicket?.id,
    attributeValues: mergeLeft(attributeValues, attributeValuesForForm),
  }
}
