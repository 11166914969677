import { memo, useEffect } from "react"
import { connect } from "react-redux"
import { always, applySpec, compose, cond, defaultTo, equals, filter, map, pluck, prop, propEq } from "ramda"
import { ControlLabel, Tab, Tabs } from "react-bootstrap"
import { useMutation, useQuery } from "urql"
import { Switch } from "@ninjaone/components"
import { getTicketingForms, getTicketRuleById } from "js/includes/common/client"
import { useForm, useMountedState, useSorted } from "js/includes/common/hooks"
import {
  localized,
  localizationKey,
  reportErrorAndShowMessage,
  showSuccessMessage,
  validations,
} from "js/includes/common/utils"
import Loading from "js/includes/components/Loading"
import {
  StyledCol,
  StyledForm,
  StyledFormControl,
  StyledFormGroup,
  StyledModal,
} from "js/includes/components/Styled/Form"
import { getTags } from "js/includes/ticketing/graphql"
import { fetchTicketStatuses as _fetchTicketStatuses } from "js/state/actions/ticketing"
import { createTicketRuleset, updateTicketRuleset } from "./graphql"
import { ConditionReset, ConditionRetrigger, TicketCreate } from "./tabs"

const getTicketFormsOptions = compose(
  map(
    applySpec({
      value: prop("id"),
      label: prop("name"),
    }),
  ),
  filter(propEq("active", true)),
  defaultTo([]),
)

const getNewModalTitle = cond([
  [equals("SCRIPT_RULE"), always(localizationKey("New Script Ticket Automation"))],
  [equals("CONDITION_RULE"), always(localizationKey("New Condition Ticket Automation"))],
  [equals("ACTIVITY_RULE"), always(localizationKey("New Activity Ticket Automation"))],
])

const getEditModalTitle = cond([
  [equals("SCRIPT_RULE"), always(localizationKey("Edit Script Ticket Automation"))],
  [equals("CONDITION_RULE"), always(localizationKey("Edit Condition Ticket Automation"))],
  [equals("ACTIVITY_RULE"), always(localizationKey("Edit Activity Ticket Automation"))],
])

const validateRetriggerStatus = (value, values) => {
  const success = values.createNewTicket ? true : !!value
  const message = !success ? localized("Required") : ""
  return {
    success,
    message,
  }
}

const TicketRulesetModal = memo(({ unmount, readonly = false, id, fetchRulesetList, type, fetchTicketStatuses }) => {
  const isEditing = !!id
  const isConditionRule = type === "CONDITION_RULE"

  const [ticketForms, setTicketForms] = useMountedState([])
  const [fetchingTicketForms, setFetchingTicketForms] = useMountedState(false)
  const [fetchingRule, setFetchingRule] = useMountedState(false)

  const { values, validation, validateForm, onChange, validateFormFields } = useForm({
    fields: {
      enabled: true,
      name: "",
      ticketFormId: null,
      status: null,
      priority: null,
      severity: null,
      tags: [],
      ...(isConditionRule && {
        resetStatus: null,
        resetTimeValue: 0,
        resetTimeUnit: "HOURS",
        createNewTicket: false,
        retriggerStatus: null,
        retriggerTags: [],
        retriggerPriority: null,
        retriggerSeverity: null,
      }),
      type,
      useOwnerAsRequester: false,
    },
    validate: {
      name: validations.required,
      ticketFormId: validations.required,
      status: validations.required,
      ...(isConditionRule && {
        resetStatus: validations.required,
        retriggerStatus: validateRetriggerStatus,
      }),
    },
  })

  useEffect(() => {
    ;(async () => {
      if (!id) return
      try {
        setFetchingRule(true)
        const { data, error } = await getTicketRuleById(id)
        if (error) throw error

        const { ticketRulesetById } = data

        onChange({
          enabled: ticketRulesetById?.enabled ?? true,
          name: ticketRulesetById?.name ?? "",
          ticketFormId: ticketRulesetById?.conditionTriggerSettings?.ticketFormId ?? null,
          status: ticketRulesetById?.conditionTriggerSettings?.status ?? null,
          priority: ticketRulesetById?.conditionTriggerSettings?.priority ?? null,
          severity: ticketRulesetById?.conditionTriggerSettings?.severity ?? null,
          tags: ticketRulesetById?.conditionTriggerSettings?.tags?.map(tag => ({ label: tag, value: tag })) ?? [],
          useOwnerAsRequester: !!ticketRulesetById?.conditionTriggerSettings?.useOwnerAsRequester,
          ...(isConditionRule && {
            resetStatus: ticketRulesetById?.conditionResetSettings?.status ?? null,
            resetTimeValue: ticketRulesetById?.conditionResetSettings?.time?.value ?? 0,
            resetTimeUnit: ticketRulesetById?.conditionResetSettings?.time?.unit ?? "HOURS",
            createNewTicket: ticketRulesetById?.conditionReTriggerSettings?.createNewTicket ?? false,
            retriggerStatus: ticketRulesetById?.conditionReTriggerSettings?.status ?? null,
            retriggerTags:
              ticketRulesetById?.conditionReTriggerSettings?.tags.map(tag => ({ label: tag, value: tag })) ?? [],
            retriggerPriority: ticketRulesetById?.conditionReTriggerSettings?.priority ?? null,
            retriggerSeverity: ticketRulesetById?.conditionReTriggerSettings?.severity ?? null,
          }),
        })
      } catch (error) {
        reportErrorAndShowMessage(error)
      } finally {
        setFetchingRule(false)
      }
    })()
  }, [id, onChange, setFetchingRule, isConditionRule])

  useEffect(() => {
    ;(async () => {
      try {
        setFetchingTicketForms(true)

        const ticketForms = await getTicketingForms()
        setTicketForms(ticketForms)
      } catch (error) {
        reportErrorAndShowMessage(error, localizationKey("Error fetching ticket forms"))
      } finally {
        setFetchingTicketForms(false)
      }
    })()
  }, [setFetchingTicketForms, setTicketForms])

  useEffect(fetchTicketStatuses, [fetchTicketStatuses])

  const [{ fetching: fetchingTags, data: _tagsData }] = useQuery({
    query: getTags,
  })

  const sortedTags = useSorted(_tagsData?.tags)
  const tagsData = {
    tags: sortedTags,
  }

  const [{ fetching: executingMutation }, executeMutation] = useMutation(
    isEditing ? updateTicketRuleset : createTicketRuleset,
  )

  const {
    enabled,
    name,
    ticketFormId,
    tags,
    status,
    priority,
    severity,
    resetStatus,
    resetTimeValue,
    resetTimeUnit,
    createNewTicket,
    retriggerStatus,
    retriggerTags,
    retriggerPriority,
    retriggerSeverity,
    type: ruleType,
    useOwnerAsRequester,
  } = values

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm()) {
      const { error } = await executeMutation({
        ruleset: {
          enabled,
          name,
          type: ruleType,
          conditionTriggerSettings: {
            ticketFormId,
            priority,
            severity,
            status,
            tags: pluck("value", tags),
            useOwnerAsRequester,
          },
          ...(isConditionRule && {
            conditionResetSettings: {
              status: resetStatus,
              time: !!resetTimeValue ? { value: resetTimeValue, unit: resetTimeUnit } : null,
            },
            conditionReTriggerSettings: {
              createNewTicket,
              status: retriggerStatus ?? status,
              tags: !createNewTicket ? pluck("value", retriggerTags) : [],
              priority: !createNewTicket ? retriggerPriority : null,
              severity: !createNewTicket ? retriggerSeverity : null,
            },
          }),
          ...(isEditing ? { id } : {}),
        },
      })

      if (!error) {
        showSuccessMessage()
        fetchRulesetList && (await fetchRulesetList())
        unmount && unmount()
      } else {
        reportErrorAndShowMessage(
          error,
          isEditing ? localizationKey("Error updating ticket ruleset") : localizationKey("Error saving ticket ruleset"),
        )
      }
    }
  }

  const ticketFormsOptions = getTicketFormsOptions(ticketForms)

  const fetching = fetchingTicketForms || fetchingTags || fetchingRule
  const ticketCreateError = validation.success.form === false || validation.success.status === false
  const conditionResetError = validation.success.resetStatus === false
  const conditionRetriggerError = validation.success.retriggerStatus === false

  return (
    <StyledForm horizontal onSubmit={handleSubmit} marginTop="0">
      <StyledModal
        title={isEditing ? getEditModalTitle(type) : getNewModalTitle(type)}
        saveText={localizationKey("Save")}
        closeText={localizationKey("Cancel")}
        submittable
        close={unmount}
        disabledSaveBtn={executingMutation || readonly}
        minHeight={500}
      >
        {fetching ? (
          <Loading />
        ) : (
          <>
            <StyledFormGroup id="form-group-ruleset-enabled-container" marginBottom={2}>
              <StyledCol xs={12} display="inline-block" flex="none" componentClass={ControlLabel}>
                {localized("Enabled")}
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <Switch disabled={readonly} checked={enabled} onChange={checked => onChange("enabled", checked)} />
              </StyledCol>
            </StyledFormGroup>
            <StyledFormGroup
              id="form-group-ruleset-name-container"
              marginBottom={2}
              validationState={validation.success.name === false ? "error" : null}
            >
              <StyledCol
                xs={12}
                display="inline-block"
                flex="none"
                componentClass={ControlLabel}
                htmlFor="automation-name"
              >
                {localized("Name")} *
              </StyledCol>
              <StyledCol xs={12} flexDirection="column" marginTop={2}>
                <StyledFormControl
                  id="automation-name"
                  value={name}
                  onChange={e => onChange("name", e.target.value)}
                  onBlur={() => validateForm(["name"])}
                  disabled={readonly}
                  maxLength={50}
                />
              </StyledCol>
            </StyledFormGroup>

            <Tabs
              id="ticketing-condition-automation-tabs"
              defaultActiveKey="ticketCreate"
              className="custom-nav-tabs text-align-left"
              mountOnEnter={true}
            >
              <Tab
                eventKey="ticketCreate"
                title={localized("On Ticket Create")}
                tabClassName={ticketCreateError ? "tab-error" : ""}
              >
                <TicketCreate
                  {...{
                    tagsData,
                    onChange,
                    values,
                    validation,
                    ticketFormsOptions,
                    readonly,
                  }}
                />
              </Tab>
              {isConditionRule && (
                <Tab
                  eventKey="conditionReset"
                  title={localized("On Condition Reset")}
                  tabClassName={conditionResetError ? "tab-error" : ""}
                >
                  <ConditionReset
                    {...{
                      values,
                      validation,
                      onChange,
                      readonly,
                    }}
                  />
                </Tab>
              )}
              {isConditionRule && (
                <Tab
                  eventKey="conditionRetrigger"
                  title={localized("On Condition Retrigger")}
                  tabClassName={conditionRetriggerError ? "tab-error" : ""}
                >
                  <ConditionRetrigger
                    {...{
                      values,
                      tagsData,
                      validation,
                      onChange,
                      validateFormFields,
                      readonly,
                    }}
                  />
                </Tab>
              )}
            </Tabs>
          </>
        )}
      </StyledModal>
    </StyledForm>
  )
})

export default connect(null, { fetchTicketStatuses: _fetchTicketStatuses })(TicketRulesetModal)
